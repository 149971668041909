import { 
    ChakraProvider, 
    HStack, 
    SimpleGrid, 
    VStack, 
    Center, 
    Heading, 
    Image,
    Box,
    Container,
    Text,
    Stack,
    Icon,
    Link
} from '@chakra-ui/react';

import { FaGithub } from "react-icons/fa";
import { FaLink } from "react-icons/fa";

import './App.css';

function App() {
  return (
    <ChakraProvider>
        {/* <Center bg='black' h='100vh' color='white'>
            <Heading>
                Netipat Resume
            </Heading>
        </Center> */}

        <Container maxW={'3xl'}>
            <Stack
                as={Box}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 20, md: 36 }}
            >
                <Box>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        color={"green.400"}
                    >
                        Fortfolio
                    </Heading>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                    >
                        Netipat Suksai
                    </Heading>
                </Box>
                <Text color={'gray.500'} textAlign={'center'}>
                    Hello there! I'm Netipat, a recent graduate in Computer Engineering from King Mongkut's University of Technology North Bangkok (KMUTNB).
                    I am passionate about creating and developing technology that impacts people's daily lives and eager to learn how to apply technology to
                    solve engineering problems and software development challenges.
                </Text>
                <VStack align={'left'}>
                    <HStack>
                        <Icon as={FaGithub} boxSize={10}/>
                        <Link href="https://github.com/iBeamKung" isExternal>
                            github.com/iBeamKung
                        </Link>
                    </HStack>
                </VStack>
            </Stack>
        </Container>

        <Box bg='black'>    
            <Container maxW={'4xl'}>
                <SimpleGrid columns={{ base: 1, xl: 2 }} alignItems={"center"}>
                    <Stack
                        as={Box}
                        spacing={{ base: 8, md: 14 }}
                        py={{ base: 20, md: 36 }}
                    >
                        <Heading
                                fontWeight={600}
                                fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                                color={"purple.400"}
                            >
                                PM 2.5 Detector
                        </Heading>
                        <Text color={'gray.500'} textAlign={'left'}>
                        A web application that utilizes React as the front-end and Python FastAPI as the back-end to connect with an ESP32 board for collecting temperature and PM2.5 dust data during travel.
                        </Text>
                        <VStack align={'left'} color={'white'}>
                            <HStack>
                                <Icon as={FaLink} boxSize={6}/>
                                <Link href="https://embedded.netipat.work/" isExternal>
                                    https://embedded.netipat.work/
                                </Link>
                            </HStack>
                        </VStack>
                    </Stack>
                    <Center px={5}>
                        <Image borderRadius={"xl"} src='./assets/img/Embed.png'/>
                    </Center>
                </SimpleGrid>
            </Container>
        </Box>
        
    </ChakraProvider>
  );
}

export default App;
